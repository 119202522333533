:root {
  --dark: #39393a;
  --light: #f0f0f0;
  --primary: #297373;
  --secondary: #bfab25;
}

* {
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
}

html {
  scroll-behavior: smooth;
  padding: 0px;
  margin: 0px;
  color: var(--light);
}

body {
  margin: 0px;
  padding: 0px;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span,
nav,
a,
h1,
h2,
h3 {
  margin: 0px;
  color: inherit;
}

p,
span,
nav,
h1,
h2,
h3 {
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
 min-width: 100vw;
 min-height: 100vh;
}

.dotted {
  background-color: var(--dark);
  background-image: radial-gradient(#2d2d2d 1px, transparent 0);
  background-size: 25px 25px;
  background-position: -25px -25px;
}

.title {
  text-align: center;
  white-space: nowrap;
  font-size: 4rem;
  color: var(--light);
  font-family: "Bricolage Grotesque", sans-serif;
  font-weight: 600;
  transition: all 200ms;
  text-shadow: 2.5px 2.5px #080808,
              5px 5px #181818,
              7.5px 7.5px #282828;
}
.title:focus,
.title:hover {
  text-shadow: 5px 5px #080808,
              10px 10px #181818,
              15px 15px #282828;
}

@media screen and (max-width: 880px) {
  .title {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }
  
}

.content {
  padding: 0px 5px;
  gap: 10px;
  min-height: 100vh;
  height: fit-content;
  width: min(95%, 1200px);
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
